.btn-success {
    color: #fff;
    background-color: #e20713;
    border-color: #e20713; }
.btn-success:hover {
    color: #fff;
    background-color: #B20309;
    border-color: #B20309; }
.btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #e20713;
    border-color: #e20713;
    -webkit-box-shadow: 0 0 0 0.15rem rgba(178, 3, 9, 0.5);
    box-shadow: 0 0 0 0.15rem rgba(178, 3, 9, 0.5); }
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #e20713;
    border-color: #e20713; }
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #e20713;
    border-color: #e20713; }
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.15rem rgba(178, 3, 9, 0.5);
    box-shadow: 0 0 0 0.15rem rgba(178, 3, 9, 0.5); }

.bg-primary{
    background-color: #B20309!important;
}

#page-topbar {
    background-color: #B20309!important;
}